.card-v2 {
  transition: all 0.3s ease;

  &.selected,
  &:hover {
    cursor: pointer;
    background-color: #b28937;

    h4 {
      color: black !important;
    }
  }
}
