.presale-2 {
  max-width: 1100px;
  color: white;

  p {
    margin-bottom: 0.3rem;
    line-height: 1.4;
    color: #b2b6b6;
    span {
      color: #d8a43b;
    }
  }

  .gold {
    font-size: 40px;
    color: #d8a43b;
    font-family: 'Cormorant Garamond', serif;
  }

  h2 {
    font-family: 'Cormorant Garamond', serif;
    color: #b2b6b6 !important;
  }

  .card {
    text-align: center;
    box-shadow: 0px -1px 14px 0 #b28937a3;
    border: 0.7px solid #b2893752;
    padding: 1.5rem 0;

    .gold {
      font-size: 30px;
      font-family: 'DM Sans', sans-serif;
    }
  }
}

.btn-presale {
  background-color: #d8a43b !important;
  border: none !important;
  color: black !important;
  font-weight: bold;
  border-radius: 4px;
  width: 80%;

  &.btn-small {
    width: 160px;
    padding: 0.8rem 1rem;
  }

  &:hover {
    background-color: #d6bc87 !important;
    color: black !important;
  }
}

.modal-content {
  p {
    color: #d8a43b;
  }

  button {
    color: #b2b6b6;
  }
}

.buy-tickets {
  .modal-content {
    background-color: black;
    color: #b2b6b6;
    box-shadow: 0px -1px 14px 0 #b28937a3;
    border: 0.7px solid #b2893752;
    padding: 1.5rem 0;

    p {
      color: white;
    }

    .gold {
      font-size: 26px;
      color: #d8a43b;
    }

    .bold {
      font-weight: bold;
    }

    .stats {
      font-size: 14px;
    }

    .circle {
      width: 40px !important;
      height: 40px !important;
      border-radius: 50%;
      background-color: #d8a43b !important;
      border: none;
      color: white;
      font-size: 20px;
      padding: 0;
    }
  }
}

.d-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
