section {
  background-color: transparent !important;
}

.particle {
  position: absolute;
  border-radius: 50%;
}

@for $i from 1 through 30 {
  @keyframes particle-animation-#{$i} {
    100% {
      transform: translate3d(
        (random(90) * 1vw),
        (random(90) * 1vh),
        (random(100) * 1px)
      );
    }
  }

  .particle:nth-child(#{$i}) {
    animation: particle-animation-#{$i} 40s infinite;
    $size: random(4) + 8 + px;
    opacity: random(30) / 100;
    height: 5px;
    width: 5px;
    animation-timing-function: linear;
    animation-delay: -$i * 0.01s;
    transform: translate3d(
      (random(90) * 1vw),
      (random(90) * 1vh),
      (random(100) * 1px)
    );
    background: #b28937;
    box-shadow: 0px 0px $size $size #b28937;
  }
}
